<template>
  <div class="w-full h-full pt-5">
    <div class="flex">
      <h1 class="text-xl text-left font-extrabold pl-4">Organisation</h1>
      <div class="w-7/12 mt-1 ml-4">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div>
      <div class="mx-3 mt-6">
        <Card class="mt-6 p-5">
          <div class="flex mb-6" style="justify-content: flex-end">
            <Button
              :background="appearance.buttonColor || defaultBTNColor"
              class="text-white" @click="handleClick"
            >
              Add Functional Unit
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="showOrgStructure"
            >
              View Org’ Hierarchy
            </Button>
          </div>
          <template v-slot:footer>
            <card-footer
              printcard
              reloadcard
              @print="print"
              @reload="reload()"
              @sortType="handleSort($event)"
              @searchResult="handleSearch($event)"
            >
              <div slot="leftItems">
                <card
                  @click.native="handleAddSupervisor()"
                  class="w-auto h-15 flex p-2 cursor-pointer"
                >
                  <icon icon-name="math_plus" size="xs" />
                  <p class="ml-3 text-darkPurple">Add Supervisory Unit</p>
                </card>
              </div>
            </card-footer>
          </template>
        </Card>
      </div>
      <div v-if="!showError" class="w-full h-full p-3">
        <sTable
          :headers="functionHeader"
          :items="functionArray"
          :loading="loadingData"
          :pagination-list="metaData"
          aria-label="function table"
          @page="handlePage($event)"
          @filter="handleFilter($event)"
          @itemsPerPage="handleItemsPerPage($event)"
          page-sync
          id="printMe"
          class="w-full"
          style="width: 100%"
          has-number
        >
          <!-- page-sync -->
          <template v-slot:item="{ item }">
            <span v-if="item.hierarchy">{{
              item.data.hierarchy ? item.data.hierarchy.name : "------"
            }}</span>
            <span v-else-if="item.reportsTo">{{
              item.data.reportsTo ? item.data.reportsTo.name : "------"
            }}</span>
            <span v-else-if="item.id">
              <Menu left top="-150" margin="24" class="my-2 p-0">
                <template v-slot:title>
                  <icon icon-name="more_icon" size="xs" />
                </template>
                <div style="width: 116px; height: 81px" class="py-3 px-2">
                  <div
                    class="flex py-1 px-3 more h-8 cursor-pointer"
                    @click="handleEdit(item.data.id)"
                  >
                    <icon
                      icon-name="edit"
                      class-name="text-blueCrayola mr-2"
                      size="xsm"
                    />
                    <p class="pt-1">Edit</p>
                  </div>
                  <div
                    class="flex px-3 py-1 more h-8 cursor-pointer"
                    @click="confirmDeleteFunction(item.data.id)"
                  >
                    <icon
                      icon-name="delete"
                      class-name="text-desire mr-2"
                      size="xsm"
                    />
                    <p class="pt-1">Delete</p>
                  </div>
                </div>
              </Menu>
            </span>
          </template>
        </sTable>
      </div>
      <div v-else>
        <error-page />
      </div>
    </div>
    <right-side-bar v-if="openModal" @close="openModal = false" close-button>
      <template v-slot:title>
        <p class="text-darkPurple">KPA Summary</p>
      </template>
      <template v-slot:subtitle>
        <p class="font-semibold text-darkPurple capitalize">
          {{ kpaSummary ? kpaSummary.name : null }}
        </p>
        <p class="font-semibold text-darkPurple">
          KPA summary listed for selected function.
        </p>
        <p class="font-semibold text-darkPurple">Last Edited:</p>
      </template>
      <div>
        <p class="text-darkPurple">KPA Descriptions</p>
        <div v-if="kpaSummary.kpis.length > 0">
          <ol>
            <li v-for="(kpis, index) in kpaSummary.kpis" :key="index">
              {{ kpis.name }}
            </li>
          </ol>
        </div>
        <p v-else>No KPA Summary found</p>
      </div>
    </right-side-bar>
    <Modal v-if="dialogDel">
      <Card class="p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold ml-4 my-5 text-center">
            Are you sure you want to delete this Function?
          </p>
          <div class="flex">
            <Button
              :background="appearance.buttonColor || defaultBTNColor"
              class="text-white" @click="deleteFunction"
            >
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="dialogDel = false"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
    <create-function
      :function-id="functionId"
      v-if="openFunctionModal"
      :open-modal="openFunctionModal"
      @closeFunctionModal="
        (openFunctionModal = false), getFunction(''), (functionId = null)
      "
    />
    <supervisory
      :open-modal="openSupervisoryFunction"
      v-if="openSupervisoryFunction"
      @closeSupervisoryFunctionModal="
        (openSupervisoryFunction = false), getFunction('')
      "
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Button from "@/components/Button";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";
import ErrorPage from "@/modules/Admin/error403";
import Icon from "@/components/Icon";
import RightSideBar from "@/components/RightSideBar";
import Menu from "@/components/Menu";
import Modal from "@/components/Modal";

export default {
  name: "Function",
  components: {
    Button,
    Modal,
    Breadcrumb,
    Card,
    STable,
    ErrorPage,
    CardFooter,
    Icon,
    RightSideBar,
    Menu,
    CreateFunction: () => import("./createFunction"),
    Supervisory: () => import("./supervisoryFunction"),
  },
  data() {
    return {
      functionArray: [],
      openModal: false,
      dialogDel: false,
      openFunctionModal: false,
      openSupervisoryFunction: false,
      kpaSummary: {},
      metaData: {},
      showError: false,
      loadingData: true,
      functionId: null,
      hierarchy: [],
      supervisoryArray: [],
      itemsPerPage: null,
      numberOfPage: null,
      searchWord: null,
      sortType: null,
      hierarchies: [],
      supervisoryIds: [],
      breadcrumbs: [
        {
          disabled: false,
          text: "Organisation",
          id: "",
        },
        {
          disabled: false,
          text: "Organisation Structure",
          id: "Functional Hierarchy",
        },
      ],
    };
  },
  computed: {
    functionHeader() {
      return [
        { title: "Functional unit", value: "name" },
        {
          title: "Span of control",
          value: "hierarchy",

          filter: true,
          filterItems: this.hierarchy,
        },
        { title: "COST CENTRE", value: "costCenter" },
        {
          title: "SUPERVISORY Unit",
          value: "reportsTo",

          filter: true,
          filterItems: this.supervisoryArray,
        },
        // { title: "KPA SUMMARY", value: "kpis", width: "20" },
        { title: "", value: "id", image: true },
      ];
    },
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },
  methods: {
   async handleAddSupervisor() {
    try {
      await this.$handlePrivilege("organisationStructure", "addSupervisoryUnit");
      this.openSupervisoryFunction = true;
    } catch (error) {
      this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
    }
    },
    async showOrgStructure() {
      try {
        await this.$handlePrivilege(
          "organisationStructure",
          "viewOrg.Structure"
        );
        this.$router.push({ name: "orgStructureDesign" });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async handleClick() {
      try {
        await this.$handlePrivilege(
          "organisationStructure",
          "addFunctionalUnit"
        );
        this.openFunctionModal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async handleEdit(id) {
      try {
        await this.$handlePrivilege(
          "organisationStructure",
          "editFunctionalUnit"
        );
        this.functionId = id;
        this.openFunctionModal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    queryUpdate() {
      const search = this.searchWord ? `&search=${this.searchWord}` : "";
      const sort = this.sortType ? `&sort=${this.sortType}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      const hierarchies = this.hierarchies.length
        ? `&hierarchy=${this.hierarchies.join(",")}`
        : "";
      const supervisoryIds = this.supervisoryIds.length
        ? `&reportingTo=${this.supervisoryIds.join(",")}`
        : "";
      this.getFunction(
        `${sort}${search}${pageNumber}${itemPage}${hierarchies}${supervisoryIds}`
      );
    },
    handleFilter(filterObject) {
      const { value, id } = filterObject;
      this.loadingData = true;
      switch (value) {
        case "hierarchy":
          this.hierarchies = this.checkIfPresent(id, this.hierarchies);
          return this.queryUpdate();

        case "reportsTo":
          this.supervisoryIds = this.checkIfPresent(id, this.supervisoryIds);
          return this.queryUpdate();
        default:
          this.hierarchies = [];
          this.supervisoryIds = [];
          return this.getFunction("");
      }
    },
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },
    async print() {
      await this.$htmlToPaper("printMe");
    },
    handleSearch(searchWord) {
      this.loadingData = true;
      if (searchWord) {
        this.searchWord = searchWord;
        this.queryUpdate();
      } else {
        this.searchWord = null;
        this.queryUpdate();
      }
    },
    handleSort(sortType) {
      this.loadingData = true;
      const changeSortType = sortType.split(":");
      let result;
      if (changeSortType[0] === "createdAt") {
        result = sortType;
      } else {
        result = `name:${changeSortType[1]}`;
      }
      this.sortType = result;
      this.queryUpdate();
    },
    reload() {
      this.getFunction("");
    },
    createKpaSummary(kpis, name) {
      this.kpaSummary = { kpis, name };
      this.openModal = true;
    },
    async confirmDeleteFunction(id) {
      try {
        await this.$handlePrivilege(
          "organisationStructure",
          "deleteFunctionalUnit"
        );
        this.functionId = id;
        this.dialogDel = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async deleteFunction() {
      try{
      this.dialogDel = false;
      this.loadingData = true;
      await this.$_deleteFunction(this.functionId).then(() => {
        this.getFunction("");
        this.functionId = null;
      });
    } catch (error) {
      this.$toasted.error(error.response?.data?.message, {
          duration: 5000,
        });
        this.loadingData = false;
    }
    },
    getHierarchy() {
      this.$_getHierarchy().then((_result) => {
        this.hierarchy = _result.data.hierarchies;
      });
    },
    async getFunction(payload) {
      this.$_getFunctionKpis(payload).then((result) => {
        this.metaData = result.data.meta;
        this.functionArray = result.data.functions;
        this.supervisoryArray = [...result.data.functions];
        this.loadingData = false;
      });
    },
  },
  async mounted() {
    try {
      await this.$handlePrivilege("organisationStructure", "viewRegister");
      this.getFunction("");
      this.getHierarchy();
      this.showError = false
    } catch (error) {
      this.functionArray = [];
      this.showError = true
      this.loadingData = false;
    }
  },
};
</script>

<style scoped>
.custom-radius {
  border-radius: 7px;
}

.button:focus {
  outline: none;
}

.more:hover {
  background: #eeeeee;
}
</style>
